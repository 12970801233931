export function getThumbnail(url: any, width: any, applyWebp?: boolean): any {
  let file = url ? url : '61bd73d4-8331-41dd-a2ac-6fc8e46b6fe6'
  const _URL = useDirectusUrl()

  return !applyWebp
    ? new URL(`${_URL}assets/${file}`).href
    : new URL(`${_URL}assets/${file}.webp`).href
}

// export function getThumbnailCustome(url: string, options: any): any {
//     let file = url ? url : '61bd73d4-8331-41dd-a2ac-6fc8e46b6fe6'
//     const { isChrome } = useDevice()
//     const imageFormat = isChrome ? 'webp' : 'png'
//     const _URL = useDirectusUrl()
//     let frameClass: any = []
//     if (options.base) frameClass.push(`aspect-${options.base.h}/${options.base.w}`)
//     if (options.md) frameClass.push(`md:aspect-${options.md.h}/${options.md.w}`)
//     if (options.lg) frameClass.push(`lg:aspect-${options.lg.h}/${options.lg.w}`)
//     if (options.xl) frameClass.push(`xl:aspect-${options.xl.h}/${options.xl.w}`)
//     // frameClass.push(attributes.class)
//     frameClass = frameClass.join(' ')
//     const w = { x1: options.width ?? 400, x2: Math.ceil((options.width ?? 400) * 1.25) }
//     const h = { x1: options.height ?? 400, x2: Math.ceil((options.height ?? 400) * 1.25) }
//     const src2x = `${new URL(`${_URL}assets/${file}`).href}.${imageFormat}?fit=${options.fit ?? 'inside'}&width=${
//         w.x2
//     }&height=${h.x2}&quality=${options.quality ?? 90}&format=${imageFormat}`
//     const src = `${new URL(`${_URL}assets/${file}`).href}.${imageFormat}?fit=${options.fit ?? 'inside'}&width=${
//         w.x1
//     }&height=${h.x1}&quality=${options.quanlity ?? 90}&format=${imageFormat}`
//     const src0 = `${
//         new URL(`${_URL}assets/${file}`).href
//     }.${imageFormat}?fit=inside&width=10&height=10&quality=60&format=${imageFormat}`
//     const classimg = `lazyload lazy lazy-img object-${options.fit ?? 'contain'} ${options.imageclass}`

//     return `
//         <picture class="image-wrapper transition relative block ${frameClass}">
//             <img
//                 class="${classimg}"
//                 src="${src0}"
//                 data-sizes="auto"
//                 data-srcset="${src} 1x, ${src2x} 2x"
//                 data-src="${src}"
//             />
//         </picture>
//     `
// }
